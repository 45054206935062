<template lang="pug">
v-navigation-drawer(app v-model="drawer" permament fixed color="primary" v-if="isTokenSet")

  v-divider

  v-list(dense)
    v-list-item
      v-list-item-content
        v-list-item-title
          v-btn(class="white--text text-capitalize body-2" color="#4DC5C1" block :to="{ name: 'dashboard'}")
              v-icon(left class="ml-n3") fas fa-clipboard-list
              | Painel
  v-divider

  v-list(dense)
    v-list-group(v-for="item in expansionItems" v-if="item.role.includes(user.role)" :key="item.title" v-model="item.active" no-action)
      v-icon(slot="appendIcon" x-small color="primary darken-4") fas fa-chevron-down
      template(v-slot:activator)
        v-icon(medium slot="prependIcon" color="white") {{ item.icon }}
        v-list-item-content
          v-badge(v-if="item.items.some(item => item.badge && item.badge > 0)" color="pink" dot inline) 
            v-list-item-title(class="white--text ml-8 body-2") {{ item.title }}
          v-list-item-title(v-else v-text="item.title" class="white--text ml-8 body-2")   
      v-list-item(v-if="item.role.includes(user.role)" v-for="subItem in item.items" :key="subItem.title" @click="" class="listTitle" :to="{ name: subItem.link}")
        v-list-item-content
          v-badge(v-if="subItem.badge && subItem.badge>0" color="pink" :content="subItem.badge" inline bordered)
            v-list-item-title(class="white--text") {{ subItem.title }}
          v-list-item-title(v-else class="white--text") {{ subItem.title }}  

  v-divider


  v-list(dense)
    v-list-item(v-for="item in directItems" :key="item.title")
      v-list-item-content
        v-badge(v-if="item.badge && item.badge>0" color="pink" :content="item.badge" overlap bordered offset-x="22" offset-y="14")
          v-list-item-title
            v-btn(class="white--text text-capitalize body-2" color="#4DC5C1" block :to="{ name: item.link}")
              v-icon(left class="ml-n3") {{ item.icon }}
              | {{ item.title }}
        v-list-item-title(v-else)
          v-btn(class="white--text text-capitalize body-2" color="#4DC5C1" block :to="{ name: item.link}")
            v-icon(left class="ml-n3") {{ item.icon }}
            | {{ item.title }}


  template
    div(class="pa-2")
      v-list
        v-list-item(v-for="item in singleItems" :key="item.title")
          v-btn(outlined color="white" block @click="item.action")
            v-icon(left) {{ item.icon }}
            | {{ item.title }}

  template
    div(class="pa-2")
      v-list
        v-list-item
          v-btn(outlined color="white" block @click="logout")
            v-icon(left) mdi-logout
            | Sair

</template>
<script>
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'

import Vue from 'vue'
import findIndex from 'lodash/findIndex'
Vue.prototype.findIndex = findIndex

export default {
  name: 'SideMenu',
  data () {
    return {

      drawer: true,
      // expansionItems: ,
      directItems: [
      ],
      singleItems:[
      ]
    }
  },
  computed: {
    ...mapGetters(['isTokenSet', 'user', 'dash']),
    expansionItems() {
      return [
        { title: 'Usuários',
          icon: 'fas fa-user',
          items: [
            { title: 'Emails', link: 'search-users' },
          ],
          role: ['super', 'admin']
        },
        { title: 'Financeiro',
          icon: 'fas fa-money-check-alt',
          items: [
            { title: 'Resgates promotoras', link:'financial-withdraws', badge: (this.dash.notifications === undefined || this.dash.notifications.promoterWithdraws === undefined) ? 0 : this.dash.notifications.promoterWithdraws },
            { title: 'Resgates parceiras', link:'partners-withdraws', badge: (this.dash.notifications === undefined || this.dash.notifications.commissionWithdraws === undefined) ? 0 : this.dash.notifications.commissionWithdraws },
            { title: 'Transações', link:'financial-transactions' },
            { title: 'Emprestimos', link:'financial-loan', badge: (this.dash.notifications === undefined || this.dash.notifications.bankRequests === undefined) ? 0 : this.dash.notifications.bankRequests },
          ],
          role: ['super']
        },
        { title: 'Financeiro',
          icon: 'fas fa-money-check-alt',
          items: [
            { title: 'Saques solicitados', link:'financial-withdraws' },
            { title: 'Transações', link:'financial-transactions' },
          ],
          role: ['admin']
        },
        { title: 'Eventos',
          icon: 'fas fa-baby-carriage',
          items: [
            { title: 'Buscar eventos', link:'events-events' },
          ],
          role: ['super', 'admin']
        },
        { title: 'Presentes',
          icon: 'fas fa-gifts',
          items: [
            { title: 'Editar lista', link:'gifts-list' },
          ],
          role: ['super', 'admin']
        },
        { title: 'Parceiros',
          icon: 'fas fa-user-friends',
          items: [
            { title: 'Editar parceiros', link:'partners-list' },
          ],
          role: ['super', 'admin']
        },

      ]
    }
  },
  methods: {
    ...mapActions(['userLogout']),
    showHide(){
      this.drawer = true
    },
    logout(){ this.userLogout() }
  },
  mounted(){}
}
</script>
<style scoped>
  .listTitle{
    background: #4DC5C1;
  }
  .gradientBg{
    background: #4DC5C1;
        background: -webkit-linear-gradient(to right, #4DC5C1, #60ECE6);
        background: linear-gradient(to right, #4DC5C1, #60ECE6);
  }

  .v-list .v-list-item--active, .v-list .v-list-item--active .v-icon {
    color: white !important;
  }
</style>
