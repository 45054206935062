export default [
    {
      path: '/users/:id?',
      name: 'search-users',
      meta: {
        requiresAuth: true,
        title: 'Usuários'
      },
      component: () =>
        import(/* webpackChunkName: "profile" */ '@/components/users/SearchUser.vue')
    }
]
  